<template>
  <router-link :class="`cardMenu cardMenu--${!showName ? 'center' : ''}`" :to="menu.to">
    <iconic class="cardMenu__icon" :name="menu.icon" />
    <span :class="`cardMenu__name`" v-if="showName">{{ menu.name }}</span>
  </router-link>
</template>

<script>
export default {
  props: ["menu", "state"],
  data() {
    return {
      showName: false,
    };
  },
  computed: {
    isShort() {
      return this.state === "short";
    },
  },
  watch: {
    isShort(val) {
      setTimeout(() => {
        this.showName = !val;
      }, 200);
    },
  },
  mounted() {
    this.showName = !this.isShort;
  },
};
</script>

<style lang="scss">
.cardMenu {
  @include Flex(row, flex-start);
  gap: 10px;
  height: 52px;
  width: 100%;
  max-width: 100%;
  padding: $mpadding;
  color: $primary-color;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
  &--center {
    justify-content: center;
  }
  &:hover {
    background-color: rgba(189, 9, 9, 0.09);
  }
  &__icon {
    font-size: 120%;
  }
  &__name {
    text-transform: uppercase;
  }
  &.router-link-active {
    color: $white;
    background-color: $primary-color;
  }
}
</style>
